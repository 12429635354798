const KeyToDirection = {
  'ArrowDown': 'down',
  'ArrowUp': 'up',
};

export class AccessibleDropdown {
  /*
  Adds keyboard controls to a dropdown menu.
  */
  elements = {
    root: null,
    anchor: null,
  };
  state = {
    focusedItemIndex: null,
    isRootFocused: false,
    childCount: null,
    focusIndex: -1,
  };

  get children() {
    return this.elements.root.querySelectorAll(':scope > ul > li > a') || [];
  }

  constructor(element) {
    if (!element) {
      return;
    }
    
    this.elements.root = element;
    this.elements.anchor = element.querySelector('a');
    this.state.childCount = this.children.length || 0;
    this.addEventListeners();
  }

  addEventListeners() {
    this.elements.anchor.addEventListener('focus', (event) => {
      this.state.isRootFocused = true;
      this.resetFocus();
    });
    this.elements.anchor.addEventListener('blur', (event) => {
      this.state.isRootFocused = false;
      this.resetFocus();
    });
    this.elements.root.addEventListener('keydown', (event) => {
      if (this.state.isRootFocused) {
        const direction = KeyToDirection[event.key];

        if (direction) {
          event.preventDefault(); // prevent keyboard page scrolling
          this.moveSelector(direction);
        }

        if (event.key === 'Enter') {
          const focusedChild = this.children[this.state.focusIndex];
          if (focusedChild) {
            event.preventDefault();
            focusedChild.click();
          }
        }
      }
    });
  }

  resetFocus() {
    this.state.focusIndex = -1;
    this.updateStyleClasses();
  }

  moveSelector(direction) {
    switch (direction) {
      case 'up':
        this.state.focusIndex -= 1;
        break;
      case 'down':
        this.state.focusIndex += 1;
        break;
    }

    if (this.state.focusIndex < 0) {
      this.state.focusIndex = -1;
    } else if (this.state.focusIndex > this.state.childCount - 1) {
      this.state.focusIndex = this.state.childCount - 1;
    }

    this.updateStyleClasses();
  }

  updateStyleClasses() {
    for (const [index, child] of Object.entries(this.children)) {
      child.classList.remove('a11y-focus');
    }
    this.children[this.state.focusIndex]?.classList.add('a11y-focus');
  }
}
