const BODY_CLASS_LIST = ['scroll-lock', 'mobile-menu-open'];

export class MobileMenu {
  elements = {
    root: null,
    buttonContainer: null,
    menuContainer: null,
  };
  state = {
    isOpen: false,
  };
  onToggle = () => {};

  constructor({element, onToggle}) {
    if (!element) {
      return;
    }

    this.elements = {
      root: element,
      buttonContainer: element.querySelector('.button-container'),
      menuContainer: element.querySelector('.menu-container'),
    };
    this.onToggle = onToggle;

    this.addTabIndexes();
    this.addEventListeners();
  }

  addTabIndexes() {
    const links = this.elements.root.querySelectorAll('ul a');
    for (const link of links) {
      link.tabIndex = 0;
    }
  }

  addEventListeners() {
    this.elements.buttonContainer.addEventListener('click', () => {
      this.state.isOpen = !this.state.isOpen;
      this.onToggle({isOpen: this.state.isOpen});

      this.elements.root.classList.remove('nav-mobile--open');
      this.elements.root.classList.remove('nav-mobile--closed');

      if (this.state.isOpen) {
        document.body.classList.add(...BODY_CLASS_LIST);
        this.elements.menuContainer.scrollTop = 0;
        this.elements.root.classList.add('nav-mobile--open');
      } else {
        document.body.classList.remove(...BODY_CLASS_LIST);
        this.elements.root.classList.add('nav-mobile--closed');
      }
    });
  }
}
