class EventEmitter {
	constructor() {
		this.eventListenersByName = new Map();
	}

	on(eventName, listener) {
		let listeners = this.eventListenersByName.get(eventName);
		if (!listeners) {
			this.eventListenersByName.set(eventName, new Set([listener]) );
		} else {
			listeners.add(listener);
		}
	}

	removeListener(eventName, listener) {
		let listeners = this.eventListenersByName.get(eventName);
		if (listeners) {
			listeners.delete(listener);
		}
	}

	emit(eventName, message) {
		console.log(`EventEmitter emit`, {eventName, message});
		let listeners = this.eventListenersByName.get(eventName);
		if (listeners) {
			listeners.forEach(listener => {
				listener.call(null, {eventName, message});
			});
		}
	}
}

export const eventEmitter = new EventEmitter();

export const Events = {
};
