import * as HomePage from '../pages/home';
import * as ContactPage from '../pages/contact';
import * as AdminPage from '../pages/admin';

const routes = {
  '^/$': HomePage,
  '^/lets-talk': ContactPage,
  '^/wp-admin/': AdminPage,
};

export function run() {
  console.log('Router.run');
  for (const [routeString, page] of Object.entries(routes)) {
    const regExp = new RegExp(routeString);
    if (regExp.test(document.location.pathname)) {
      page.run();
      break;
    }
  }
}
