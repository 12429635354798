export class ImageRotator {
  /*
  - there are 2 images: FG & BG
  - FG image = current
  - BG image = next

  Rotation sequence:
  - BG = current, FG = next (hidden)
  - swap images, show BG, hide FG (fades out)
  - fade FG image
  */
  SCALE_STEP = .0005;
  scaleEffectAnimationFrameCount = 0;

  constructor(selector) {
    this.rootElement = document.querySelector(selector);
    this.elements = {
      imageContainer: this.rootElement.querySelector('.image-container'),
    };

    // adding image using JS to prevent SEO/a11y issues
    const bottomImage = document.createElement('img');
    this.elements.imageContainer.append(bottomImage);

    this.data = this.safeParseJson(this.rootElement.dataset.model);
    this.data.delay = (Number.parseInt(this.data.delay, 10) || 10) * 1000;
    this.state = {
      nextEventTime: this.data.delay,
      imageIndex: 0,
      isPreparedToRotate: false,
    };
  }

  get foregroundImage() {
    return this.rootElement.querySelector('img:last-child');
  }

  get backgroundImage() {
    return this.rootElement.querySelector('img:first-child');
  }

  delay(duration = 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

  safeParseJson(string) {
    try {
      return JSON.parse(string);
    } catch(error) {
      console.warn(`could not parse json: ${string}`);
    }
  }

  getNextImageIndex() {
    let nextIndex = this.state.imageIndex + 1;
    if (nextIndex > this.data.items.length - 1) {
      nextIndex = 0;
    }
    return nextIndex;
  }

  watchImageLoadingState() {
    const {topImage, bottomImage} = this.elements;
    const imagesByName = {
      topImage,
      bottomImage,
    };

    for (const [imageName, image] of Object.entries(imagesByName)) {
      if (image.src && image.complete) {
        console.log(`${imageName} loaded`);
        this.state.imageLoading[imageName] = true;
      } else {
        console.log(`${imageName} not loaded, waiting...`);
        image.addEventListener('load', () => {
          console.log(`${imageName} loaded`);
          this.state.imageLoading[imageName] = true;
        });
      }
    }
  }

  waitForImageToLoad(image) {
    return new Promise((resolve) => {
      if (image.src && image.complete) {
        // console.log(`image not loaded`);
        resolve();
      } else {
        // console.log(`image not loaded, waiting...`);
        image.addEventListener('load', () => {
          // console.log(`${imageName} loaded`);
          resolve();
        });
      }
    });
  }

  preloadNextImage() {
    const foregroundImage = this.foregroundImage;
    const nextImageIndex = this.getNextImageIndex();
    foregroundImage.style.opacity = 0;
    foregroundImage.src = this.data.items[nextImageIndex].image;
    // foregroundImage.classList.add('loading');
  }

  async prepareToRotate() {
    this.state.isPreparedToRotate = false;
    this.preloadNextImage();
    await this.waitForImageToLoad(this.foregroundImage);
    await this.waitForImageToLoad(this.backgroundImage);
    // this.foregroundImage.classList.remove('loading');
    this.state.isPreparedToRotate = true;
  }

  async rotate() {
    this.state.imageIndex = this.getNextImageIndex();
    console.log(`ImageRotator.rotate imageIndex: ${this.state.imageIndex}`);

    this.elements.imageContainer.append(this.backgroundImage); // swap FG with BG

    // update link
    this.elements.imageContainer.href = this.data.items[this.state.imageIndex].link;

    // show new image
    this.backgroundImage.style.transition = 'opacity 0s';
    this.backgroundImage.style.opacity = 100;
    this.backgroundImage.style.transform = 'scale(1)';
    this.backgroundImage.$scale = 1;

    // transition out old image
    this.foregroundImage.style.transition = 'opacity 2s';
    await this.delay();
    this.foregroundImage.style.opacity = 0;

    // must wait until transition is complete before preloading new image
    await this.delay(2000);
  }

  async onAnimationFrame(timeStamp) {
    // console.log({timeStamp, next: this.state.nextEventTime});
    if (
      this.state.isPreparedToRotate
      && timeStamp >= this.state.nextEventTime
    ) {
      this.state.nextEventTime = timeStamp + this.data.delay;
      console.log(`event triggered: ${timeStamp}, nextEventTime: ${this.state.nextEventTime}`);
      await this.rotate();
      await this.prepareToRotate();
    }
    window.requestAnimationFrame(this.onAnimationFrame.bind(this));
  }

  applyScaleEffect() {
    // throttle animation to reduce CPU usage
    if (++this.scaleEffectAnimationFrameCount % 4 !== 0) {
      return;
    }
    this.backgroundImage.$scale ||= 1;
    this.backgroundImage.$scale = this.backgroundImage.$scale + this.SCALE_STEP;
    this.backgroundImage.style.transform = `scale(${this.backgroundImage.$scale})`;

    this.foregroundImage.$scale ||= 1;
    this.foregroundImage.$scale = this.foregroundImage.$scale + this.SCALE_STEP;
    this.foregroundImage.style.transform = `scale(${this.foregroundImage.$scale})`;
  }

  onAnimationFrameEffects() {
    // this.applyScaleEffect();
    window.requestAnimationFrame(this.onAnimationFrameEffects.bind(this));
  }

  run() {
    window.requestAnimationFrame(this.onAnimationFrame.bind(this));
    window.requestAnimationFrame(this.onAnimationFrameEffects.bind(this));
    this.prepareToRotate();
  }
}
