export class ContentSwitcher {
	elements = {
    root: null,
    items: [],
  };

  constructor(element) {
    if (!element) {
      return;
    }

    this.elements = {
      root: element,
      items: element.querySelectorAll('[data-id]'),
    };
  }

	show(itemId) {
		for (const item of this.elements.items) {
      item.style.display = 'none';
    }
		console.log({itemId});
		this.elements.root.querySelector(`[data-id="${itemId}"]`).style.display = 'block';
	}
}
