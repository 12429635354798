import {EventEmitter} from './EventEmitter';

export class ButtonGroup {
  elements = {
    root: null,
    buttons: [],
  };
  state = {
    selectedButton: null,
  };
  onSelect = () => {};

  get defaultButtonId() {
    return this.elements.root.dataset.defaultButton;
  }

  constructor({element, onSelect}) {
    if (!element) {
      return;
    }

    this.elements = {
      root: element,
      buttons: element.querySelectorAll('[data-id]'),
    };
    this.onSelect = onSelect;
    this.state.selectedButton = element.querySelector(`[data-id=${this.defaultButtonId}]`);

    this.addEventListeners();
    this.updateState();
  }

  addEventListeners() {
    this.elements.root.addEventListener('click', (event) => {
      if (event.target.dataset.id) {
        this.updateState((state) => {
          state.selectedButton = event.target;
        });
        this.onSelect(this.state.selectedButton.dataset.id);
      }
    });
  }

  updateState(callback = () => {}) {
    callback(this.state);

    for (const button of this.elements.buttons) {
      button.classList.remove('active');
    }
    this.state.selectedButton?.classList.add('active');
  }
}
