function delay(time = 100) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

function getAddMediaAttachments() {
  return Array.from(document.querySelectorAll('.attachments-wrapper .attachment'));
}

async function clickSelectedAttachment() {
  const insertMediaButton = document.querySelector('.media-button-insert');
  if (insertMediaButton?.disabled === false) {
    const selectedAttachment = document.querySelector('.attachments-wrapper .attachment.selected');
    selectedAttachment.scrollIntoView();
    selectedAttachment.setAttribute('is-attached', true);
    selectedAttachment.style.opacity = .3;

    await delay(200);
    insertMediaButton.click();
  }
}

function addProjectImages() {
  /*
  Add medial modal: quickly add project images.
  */
  document.addEventListener('click', async (event) => {
    if (event.target.matches('[value="Add File"]')) { // open "add media" modal from CFS
      // switch to library tab
      await delay();
      document.querySelector('#menu-item-browse')?.click();

      await delay();
      const mediaSearchInput = document.querySelector('#media-search-input');
      const postTitle = document.querySelector('#title')?.value;
      // mediaSearchInput.value = `/${postTitle}/`;
      // mediaSearchInput.dispatchEvent(new Event('input'));

      // First, you must open the modal to enter the file folder path.
      // If this path is empty, do nothing.
      if (false || !mediaSearchInput.value) {
        return;
      }

      // select an attachment that has not been attached
      await delay(1000);
      const attachments = getAddMediaAttachments();
      attachments.findLast(e => !e.getAttribute('is-attached'))?.click();

      clickSelectedAttachment();
    }
  });
}

function highlightFieldsOnClick() {
  /*
  Makes it easier to copy/paste/clear fields.
  */
  document.addEventListener('click', async (event) => {
    if (event.target.matches('#title')) {
      event.target.select();
    }
  });
}

function mediaHotkeys() {
  document.addEventListener('keydown', async (event) => {
    if (event.key === 'i') {
      clickSelectedAttachment();
    }
  });
}

export function run() {
  console.log('hi admin');

  // add media modal: "insert to post" hotkey
  mediaHotkeys();
  addProjectImages();
  highlightFieldsOnClick();
}
